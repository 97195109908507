import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ProgressBar from "./progress-bar";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import GridLoader from "react-spinners/GridLoader";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function VideoDownloader(props) {
  const [videos, setVideos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log("fetch videos?");
    fetchVideos();
  }, []);

  /*  useEffect(() => {
    const interval = setInterval(() => {
      fetchVideos(false);
    }, 5000);
    return () => clearInterval(interval);
  }, []); */

  async function fetchVideos(isSpinner = true) {
    try {
      if (isSpinner) {
        setIsLoading(true);
      }

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/convert-mp4/all/videos`);
      console.log(data, "data here");

      setIsLoading(false);
      setVideos(data);
      console.log(dayjs().format("l"), 'dayjs().format("l") here');
      return null;
    } catch (error) {
      setIsLoading(false);
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
        theme: "dark",
      });
    }
  }

  function toTime(seconds) {
    try {
      if (!seconds) {
        return "00:00:00";
      }
      if (!isNaN(seconds)) {
        var date = new Date(null);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
      }
      return "00:00:00";
    } catch (error) {
      return "00:00:00";
    }
  }

  function render() {
    if (videos) {
      return videos.map((item, i) => {
        console.log("test", item);
        return (
          <div style={style.item} key={i}>
            <div style={style.column}> {item.ObjectName} </div>
            <div style={style.column}>
              {" "}
              <span style={style.status}>Status: {item.Status}</span>{" "}
            </div>
            <div style={style.column}> {dayjs(item.DateCreated).format("LLLL")}</div>
            <div style={style.column}>
              {item.Status === "Done" ? (
                <div>
                  {/*  https://nuro-test.b-cdn.net */}
                  <a target="_blank" href={`https://downloads.nuro.video/${item.ObjectName}`} rel="noreferrer">
                    Télécharger
                  </a>
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: 800,
                  }}
                >
                  <div>Progrès:</div>

                  <div>
                    {toTime(item.CurrentDuration)} - {toTime(item.Duration)}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
    return <></>;
  }
  const style = {
    container: {
      width: "70%",
      marginLeft: 0,
      maginRight: 0,
      fontSize: "16px",
    },
    input: {
      marginBottom: "1.5rem",
      marginTop: "1rem",
      fontSize: "1.0rem",
    },
    button: {
      width: "40%",
      margin: "auto",
    },
    downloadUrl: {
      marginTop: "2rem",
    },
    item: {
      display: "flex",
      gap: 20,
      justifyContent: "space-around",
      paddingTop: 20,
    },
    status: {
      backgroundColor: "black",
      color: "white",
      padding: 5,
      borderRadius: 5,
    },
    column: {
      width: "25%",
    },
    listWrapper: {
      textAlign: "left",
      marginBottom: "100px",
    },
    loader: {
      marginTop: "2rem",
    },
  };

  return (
    <div style={style.container}>
      <h2 style={{ marginBottom: 50 }}>Liste de Vidéos :</h2>
      {isLoading ? (
        <div style={style.loader}>
          <GridLoader color="white"></GridLoader>
        </div>
      ) : (
        <></>
      )}
      <div style={style.listWrapper}>{render()}</div>
    </div>
  );
}

export default VideoDownloader;
