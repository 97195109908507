import React, { useState, useEffect } from "react";
let interval;
const ProgressBar = ({ durationProcess, currentDurationProcess, handleRefetch, resetProgressBar }) => {
  const toTime = (seconds) => {
    try {
      console.log(seconds, "SECONDS");
      if (seconds >= durationProcess) {
        return "00:00:00";
      }
      if (!isNaN(seconds)) {
        var date = new Date(null);
        console.log(date, "date");
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
      }
      return "00:00:00";
    } catch (error) {
      return "00:00:00";
    }
  };
  const [seconds, setSeconds] = useState(currentDurationProcess ? currentDurationProcess : 0);
  const [percentage, setPercentage] = useState(0);
  const [leftTime, setLeftTime] = useState(toTime(durationProcess - seconds));
  const style = {
    wrapper: {
      width: "100%",
      backgroundColor: "#acacac",
      borderRadius: "20px",
      position: "relative",
      margin: "15px 0",
      height: "30px",
    },
    styleBar: {
      opacity: 1,
      width: `${percentage}%`,
      color: "white",
      fontSize: "1rem",
    },
  };
  const toPercentage = (_seconds) => {
    if (_seconds >= durationProcess) {
      return 100;
    }
    return parseInt((_seconds * 100) / durationProcess);
  };

  useEffect(() => {
    if (interval) {
      console.log("clear interval.");
      clearInterval(interval);
      interval = null;
    }
    interval = setInterval(() => {
      setSeconds((seconds) => {
        console.log("interval", seconds);
        if (toPercentage(seconds) >= 90) {
          clearInterval(interval);
          interval = null;
        }
        return seconds + 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [resetProgressBar]);

  useEffect(() => {
    const _percentage = toPercentage(seconds);
    if (_percentage >= 90) {
      setPercentage(90);
      setLeftTime(null);
      handleRefetch();
      // clear interval
    } else {
      setPercentage(_percentage);
      setLeftTime(toTime(durationProcess - seconds));
    }
  }, [seconds]);

  useEffect(() => {
    setSeconds(currentDurationProcess);
    setLeftTime(toTime(durationProcess - seconds));
  }, [currentDurationProcess]);

  return (
    <div>
      <div className="progress" style={style.wrapper}>
        <div className="progress-bar-done" style={style.styleBar}>
          {percentage}%
        </div>
      </div>
      <div>{leftTime ? <p> Temps restant: {leftTime}</p> : ""}</div>
    </div>
  );
};

export default ProgressBar;
