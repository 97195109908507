import { Link, useParams } from "react-router-dom";

function Header() {
  console.log(process.env.TEST, "test variable");
  const style = {
    container: {
      backgroundColor: "#030d1f",
      display: "flex",
      justifyContent: "center",
      height: 50,
      paddingTop: 10,
      gap: 30,
      position: "fixed",
      width: "100%",
    },
    link: {
      paddingVertical: 100,
    },
  };

  return (
    <div className="header" style={style.container}>
      <div style={style.link}>
        <a style={style.link} href="/">
          Home
        </a>
      </div>
      <div style={style.link}>
        <Link to="/listing">Liste de vidéos</Link>
      </div>
    </div>
  );
}

export default Header;
