import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Header from "./component/header";
import VideoDownloader from "./component/video-downloader.js";
import ListingVideo from "./component/listing-video.js";
import { ToastContainer, toast } from "react-toastify";
import logo from "./assets/logo.svg";
import "./App.css";

const Home = () => (
  <div>
    <Header></Header>
    <div className="App-main">
      <img src={logo} className="App-logo" alt="logo" />
      <VideoDownloader></VideoDownloader>
    </div>
  </div>
);

const Listing = () => (
  <div>
    <Header></Header>
    <div className="App-main">
      <ListingVideo></ListingVideo>
    </div>
  </div>
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listing" element={<Listing />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
