import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ProgressBar from "./progress-bar";
import "react-toastify/dist/ReactToastify.css";
import GridLoader from "react-spinners/GridLoader";

function VideoDownloader(props) {
  const [projectUrl, setProjectUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [displayType, setDisplayType] = useState("input");
  const [durationProcess, setDurationProcess] = useState(0);
  const [currentDurationProcess, setCurrentDurationProcess] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isInterval, setIsInterval] = useState(false);
  const [resetProgressBar, setResetProgressBar] = useState(Math.random());
  console.log(process.env, "REACT_APP_MY_ENV", process.env.REACT_APP_API_URL);
  async function refetch() {
    try {
      if (!projectId) {
        return console.error("projectId is null.");
      }
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/convert-mp4/${projectId}`);
      if (data?.error) {
        toast(data.error, {
          position: "top-center",
          autoClose: 2000,
          theme: "dark",
        });
      } else if (data?.videoUrl) {
        setVideoUrl(data?.videoUrl);
        setDisplayType("download-url");
        return data?.videoUrl;
      } else if (data?.duration) {
        setCurrentDurationProcess(data?.currentDuration);
        setDurationProcess(data?.duration);
        if (displayType === "in-progress-1") {
          setResetProgressBar(Math.random());
          setDisplayType("in-progress-2");
        }
      }
      if (!isInterval) {
        setIsInterval(true);
      }
      return null;
    } catch (error) {
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
        theme: "dark",
      });
    }
  }

  // Once we reach 90%, we refetch every 15 secondes until we get a videoUrl.
  // Tmp solution until we setup either the socket or the redis table to store the progress.
  useEffect(() => {
    if (!isInterval) {
      return;
    }
    const interval = setInterval(async () => {
      console.info("Fetch every 20 secs.");
      const result = await refetch();
      if (result) {
        clearInterval(interval);
        setIsInterval(false);
      }
    }, 20000);
    // clear out the interval using the id when unmounting the component
    return () => clearInterval(interval);
  }, [isInterval]);
  async function submit(e) {
    try {
      e.preventDefault();

      if (!projectUrl) {
        return toast("Vous n'avez pas saisi d'url.", {
          position: "top-center",
          autoClose: 2000,
          theme: "dark",
        });
      }
      setIsLoading(true);
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/convert-mp4`, {
        projectUrl,
      });
      if (data?.error) {
        toast(data.error, {
          position: "top-center",
          autoClose: 6000,
          theme: "dark",
        });
      } else if (data?.videoUrl) {
        setVideoUrl(data?.videoUrl);
        setDisplayType("download-url");
      } else {
        //  resetProgressBar(Math.random());
        setProjectId(data?.projectId);
        setCurrentDurationProcess(data?.currentDuration);
        setDurationProcess(data?.duration);
        data?.fromRedis === true ? setDisplayType("in-progress-2") : setDisplayType("in-progress-1");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
      });
    }
  }

  function render() {
    if (displayType === "input") {
      return (
        <div>
          <h1 className="bold">Ajoutez votre URL Nuro et nous convertirons votre vidéo au format mp4:</h1>

          <p className="small-text">
            {" "}
            <span className="bold">Example d'un url Nuro:</span> https://watch.nuro.video/b9502ad6/5e79ef2a/module.html{" "}
          </p>
          {!isLoading ? (
            <form onSubmit={submit}>
              <label>
                <input style={style.input} type="text" placeholder="Url Nuro" onChange={(event) => setProjectUrl(event.target.value)} />
              </label>
              <input style={style.button} className="button" type="submit" value="Convertir ma vidéo" />
            </form>
          ) : (
            <div style={style.loader}>
              <GridLoader color="white"></GridLoader>
            </div>
          )}
        </div>
      );
    } else if (displayType === "download-url") {
      return (
        <div>
          <h1 className="bold">Votre vidéo à été converti. Téléchargez-là au format mp4.</h1>
          <div style={style.downloadUrl}>
            <u>
              <a target="_blank" href={videoUrl} rel="noreferrer">
                Télécharger ma vidéo.
              </a>
            </u>
          </div>
        </div>
      );
    } else if (displayType === "in-progress-1") {
      return (
        <div>
          <div style={{ paddingBottom: "0.5rem" }}>
            <h1 className="bold" style={{}}>
              Veuillez patienter pendant la préparation de la conversion.
            </h1>
            <p className="small-text bod" style={{ fontSize: "1rem" }}>
              Cette phase prend environ une minute. Le traitement de votre vidéo démarrera ensuite.
            </p>
          </div>
          <ProgressBar resetProgressBar={resetProgressBar} key={0} durationProcess={durationProcess} currentDurationProcess={currentDurationProcess} handleRefetch={() => refetch()}></ProgressBar>
        </div>
      );
    } else if (displayType === "in-progress-2") {
      return (
        <div>
          <h1 className="bold" style={{ paddingBottom: "0.5rem" }}>
            Votre vidéo est en cours de conversion.
          </h1>
          <ProgressBar resetProgressBar={resetProgressBar} key={1} durationProcess={durationProcess} currentDurationProcess={currentDurationProcess} handleRefetch={() => refetch()}></ProgressBar>
        </div>
      );
    }

    return "";
  }
  const style = {
    container: {
      width: "40%",
    },
    input: {
      marginBottom: "1.5rem",
      marginTop: "1rem",
      fontSize: "1.0rem",
    },
    button: {
      width: "40%",
      margin: "auto",
    },
    downloadUrl: {
      marginTop: "2rem",
    },
    loader: {
      marginTop: "2rem",
    },
  };

  return <div style={style.container}>{render()}</div>;
}

export default VideoDownloader;
